<template>
    <div class="about-us" id="about">
        <div class="mobile-title">
            <p>קצת עלינו</p>
        </div>
        <div class="section start">
            <div class="content">
                <div class="section-title">
                    <p>לכל סיפור יש התחלה</p>
                </div>
                <div class="section-sub-title">
                    <p>זה הסיפור שלנו</p>
                </div>
                <div class="section-content">
                    <p>שנים רבות של עבודה בתחום הקמעונאות והמכירות היוו את הזרז להקמת החברה.
                    במהלך שנות העבודה שלנו נתקלנו לא מעט פעמים בחסמים, 
                    בעיות ומגוון רחב של נושאים בהם ידענו שניתן לייעל, לשפר ולחדש באמצעות טכנולוגיה חדשה ויצירתיות.</p>
                    <br>
                    <p>
                        כאשר מצאנו פתרונות קיימים, החלו להופיע קשיים חדשים. תהליכי האפיון היו חלקיים, המוצרים היו מקובעים והיה קשה להטמיע את הפתרונות בשטח.
                        כמו כן, העלויות! העלויות פשוט לא עשו לנו שכל.
                        המפתחים אמנם שולטים בקוד, אולם לא מצאנו חברות אשר באמת מבינות את הצרכים והקשיים שלנו.
                    </p>
                    <br>
                    <p>
                        התחלנו בקטן, פיתוח פתרונות לחברות שלנו ושל החברים. טפסים, כלים לאוטומטיזציה ופתרונות ענן להנגשת הדטה.
                        ככל שעבר הזמן, התמקצנו, גייסנו ולמדנו גם על עולם ההייטק.
                        כך נולדה סייבר פארק, חברה המשלבת הון מקצועי מעולם העסקים יחד עם ההון האנושי והמקצועי של אנשי הפיתוח שלנו.
                        בסייבר פארק אנו מבינים את חשיבות האפיון, את הקושי בהטמעה בשטח ואת שאר האלמנטים העסקיים הדרושים להצלחה - מעבר לפיתוח המוצר עצמו.
                    </p>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
export default {
setup(){
    onMounted(() => {
        const about = document.getElementById('about');
        if(about){
            about.classList.toggle('fade-in')
        }
    })
    return{

    }
}
}
</script>

<style scoped>
.about-us{
    width: 100%;
    height: 100%;
    overflow-x: auto;
    display: grid;
    grid-auto-columns: 100%;
    grid-auto-rows: 100%;
    padding-right: 65px;
}
.section{
    width: calc(100%);
    height: 100%;
}
.start{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-image: url('~@/assets/Svgs/about_park.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 50vw;
}
.content{
    width: 100%;
    height: 100%;
}
.section-title{
    width: 100%;
    height: 20%;
    font-size: 10vh;
    display: flex;
    align-items: center;
    color: rgba(245, 245, 245, 0.842);
    padding-right: 30px;
}
.section-title p{
    text-shadow: 2px 2px 10px #0000008a;
}
.section-sub-title{
    width: 100%;
    height: 10%;
    font-size: 8vh;
    display: flex;
    align-items: center;
    color: rgba(99, 253, 253, 0.842);
    text-shadow: 1px 1px 5px #0000008a;
    padding-right: 10vw;
    margin-bottom: 3vh;
}
.section-content{
    width: 50%;
    height: 60%;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    color: rgba(245, 245, 245, 0.842);
    font-size: 2.35vh;
}
.mobile-title{
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100%);
    height: 70px;
    justify-content: center;
    align-items: center;
    color: whitesmoke;
    display: none;
    z-index: 1;
    font-size: 4vh;
}
@media only screen and (max-width: 1004px) {
    .mobile-title{
        display: flex;
    }
    .about-us{
        padding: 10px;
    }
    .section-title{
        height: auto;
        font-size: 5vh;
        margin-top: 70px;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .section-sub-title{
        font-size: 3.5vh;
        padding-right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .section-content{
        width: 100%;
        padding-right: 0;
        font-size: 2vh;
    }

}

.fade-in {
	-webkit-animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>